import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import styles from './Home.module.css'
import { FaRegStar, FaStar } from 'react-icons/fa';
import { BASE_URL_API } from '../References/URLs';

const Home = () => {
    let params = useParams();
    let paramsArr = params.key.split("/");
    let key = paramsArr[0];

    const [isLoadingForm, setIsLoadingForm] = useState(false);

    const [selectedStar, setSelectedStar] = useState(0);
    const [starSelectedServiceResults, setStarSelectedServiceResults] = useState(0)
    const [starSelectedStaffBehaviour, setStarSelectedStaffBehaviour] = useState(0)
    const [starSelectedSalonEnvironment, setStarSelectedSalonEnvironment] = useState(0)

    const [whatCanBeImprovedOptions, setWhatCanBeImprovedOptions] = useState(['Overall service', 'Staff Behaviour', 'Cleanliness', 'Salon Interior', 'Staff Skills', 'Music Playlist', 'Hospitality'])
    const [wcbImprovedSelected, setWcbImprovedSelected] = useState([])

    const [careToShareALilMoreTxt, setCareToShareALilMoreTxt] = useState('');
    const [reviewSubmittingStatus, setReviewSubmittingStatus] = useState('NOT_YET_SUBMITTED'); //ALREADY_RATED, NOT_YET_SUBMITTED, SUBMITTED, SUBMITTING, INVALID_KEY

    const selectStarHandler = (v) => {
        setSelectedStar(selectedStar == v ? 0 : v)
    }

    const selectStarHandlerSR = (v) => {
        setStarSelectedServiceResults(starSelectedServiceResults == v ? 0 : v);
    }

    const selectStarHandlerSB = (v) => {
        setStarSelectedStaffBehaviour(starSelectedStaffBehaviour == v ? 0 : v);
    }

    const selectStarHandlerSE = (v) => {
        setStarSelectedSalonEnvironment(starSelectedSalonEnvironment == v ? 0 : v);
    }

    const whatCanBeImprovedHandler = (k) => {
        wcbImprovedSelected.includes(k) === true ? setWcbImprovedSelected((prevState) => prevState.filter((pi) => pi !== k)) : setWcbImprovedSelected([...wcbImprovedSelected, k]);
    }

    const careToShareALilTxtHandler = (k) => {
        setCareToShareALilMoreTxt(k);
    }


    const getReviewStatus = async () => {
        if (key !== "") {
            setIsLoadingForm(true);
            const getReviewStatusResponse = await fetch(BASE_URL_API + "getReviewStatus",
                {
                    method: "POST",
                    body: JSON.stringify({
                        feedbackKey: key
                    })
                });

            if (!getReviewStatusResponse.ok) {
                console.log("Something went wrong : Server Error!")
            } else {
                const getReviewStatusRespo = await getReviewStatusResponse.json();
                if (getReviewStatusRespo.status === "success") {
                    setReviewSubmittingStatus(getReviewStatusRespo.response);
                    console.log(getReviewStatusRespo.response)
                } else {
                    if (getReviewStatusRespo.response === "invalidKey") {
                        setReviewSubmittingStatus('INVALID_KEY');
                    } else {
                        console.log("something went wrong, try again later!");
                    }
                }
            }
            setIsLoadingForm(false);
        } else {
            console.log("Invalid Key")
        }
    }


    const submitReviewHandler = async () => {
        if (key !== "") {
            setReviewSubmittingStatus('SUBMITTING');
            console.log(selectedStar, careToShareALilMoreTxt, starSelectedServiceResults, starSelectedStaffBehaviour, starSelectedSalonEnvironment, wcbImprovedSelected)
            const submitReviewResponse = await fetch(BASE_URL_API + "submitReview",
                {
                    method: "POST",
                    body: JSON.stringify({
                        feedbackKey: key,
                        selectedStar: selectedStar,
                        careToShareALilMoreTxt: careToShareALilMoreTxt,
                        starSelectedServiceResults: starSelectedServiceResults,
                        starSelectedStaffBehaviour: starSelectedStaffBehaviour,
                        starSelectedSalonEnvironment: starSelectedSalonEnvironment,
                        wcbImprovedSelected: JSON.stringify(wcbImprovedSelected)
                    })
                });

            if (!submitReviewResponse.ok) {
                console.log("Something went wrong : Server Error!");
            } else {
                const submitReviewRespo = await submitReviewResponse.json();
                if (submitReviewRespo.status === "success") {
                    setReviewSubmittingStatus('SUBMITTED');
                } else {
                    setReviewSubmittingStatus('NOT_YET_SUBMITTED');
                    console.log(submitReviewRespo);
                }
                console.log(submitReviewRespo);
            }
        } else {
            alert("Something went wrong : Server Error!");
        }
    }


    useEffect(() => {
        getReviewStatus().then(
            console.log("fetched")
        );
    }, [key])


    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#ECFAFF");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#ECFAFF";
            document.head.appendChild(meta);
        }
    }, []);


    if (isLoadingForm) {
        return (
            <div className={styles.mainLoader}>
                <img src="https://res.cloudinary.com/iamironman/image/upload/v1633344839/Display-Loading_tveevd.gif" />
                <p>
                    Hold on a sec,
                </p>
                <p>
                    we are fetching the feedback form!
                </p>
            </div>
        )
    }


    if (reviewSubmittingStatus === "INVALID_KEY") {
        return (
            <div className={styles.mainWrapperInvalidKey}>
                <div className={styles.mainWrapperContent}>
                    <div className={styles.textContainer}>
                        <div className={styles.whoops}>
                            whoops...
                        </div>
                        <div className={styles.notAValidPage}>
                            that's not a valid page!
                        </div>
                    </div>
                    <div className={styles.contactUsBtn}>
                        <a href="https://wa.me/918929460356?text=Hey,%2AI%20am%20not%20able%20to%20review%20your%20services.%2AKindly%20help!">
                            Contact Us
                        </a>
                    </div>
                </div>
            </div>
        )
    }


    if (reviewSubmittingStatus === "ALREADY_RATED") {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.successfullySubmitted}>
                    <img src="https://res.cloudinary.com/iamironman/image/upload/v1633813629/web-icons/green-tick_mturmu.png" />
                    <p>You have already rated this visit!</p>
                </div>
            </div>
        )
    }


    if (reviewSubmittingStatus === "SUBMITTED") {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.successfullySubmitted}>
                    <img src="https://res.cloudinary.com/iamironman/image/upload/v1633813629/web-icons/green-tick_mturmu.png" />
                    <p>Successfully submitted!</p>
                </div>
            </div>
        )
    }

    return (
        <div className={styles.mainWrapper}>
            {reviewSubmittingStatus === "SUBMITTING" &&
                <div className={styles.mainLoader}>
                    <img src="https://res.cloudinary.com/iamironman/image/upload/v1633344839/Display-Loading_tveevd.gif" />
                    <p>
                        Submitting...
                    </p>
                </div>
            }
            <section className={styles.mainHeaderWrapper}>
                <div className={styles.mainHeaderContainer}>
                    <div className={styles.headerTitle}>
                        Share Your Experience
                        <div className={styles.mainHeaderSubCaption}>
                            Your feedback will be valued and rewarded!
                        </div>
                    </div>
                </div>
            </section>
            <section className={styles.mainBodyWrapper}>
                <div className={styles.mainBodyContainer}>
                    <div className={styles.initiateContainer}>
                        <div className={styles.initiateTitle}>
                            Please leave your feedback!
                        </div>
                        <div className={styles.initiateSubTitle}>
                            Rate how much you are satisfied with the service?
                        </div>
                        <div className={styles.starContainer}>
                            {selectedStar > 0 ?
                                <span
                                    onClick={() => selectStarHandler(1)}
                                >
                                    <FaStar size={38} color="red" className={styles.eachStar} />
                                </span>
                                :
                                <span
                                    onClick={() => selectStarHandler(1)}
                                >
                                    <FaRegStar size={38} color="dfdfdf" className={styles.eachStar} />
                                </span>

                            }
                            {selectedStar > 1 ?
                                <span
                                    onClick={() => selectStarHandler(2)}
                                >
                                    <FaStar size={38} color="red" className={styles.eachStar} />
                                </span>
                                :
                                <span
                                    onClick={() => selectStarHandler(2)}
                                >
                                    <FaRegStar size={38} color="dfdfdf" className={styles.eachStar} />
                                </span>

                            }
                            {selectedStar > 2 ?
                                <span
                                    onClick={() => selectStarHandler(3)}
                                >
                                    <FaStar size={38} color="red" className={styles.eachStar} />
                                </span>
                                :
                                <span
                                    onClick={() => selectStarHandler(3)}
                                >
                                    <FaRegStar size={38} color="dfdfdf" className={styles.eachStar} />
                                </span>

                            }
                            {selectedStar > 3 ?
                                <span
                                    onClick={() => selectStarHandler(4)}
                                >
                                    <FaStar size={38} color="red" className={styles.eachStar} />
                                </span>
                                :
                                <span
                                    onClick={() => selectStarHandler(4)}
                                >
                                    <FaRegStar size={38} color="dfdfdf" className={styles.eachStar} />
                                </span>

                            }
                            {selectedStar > 4 ?
                                <span
                                    onClick={() => selectStarHandler(5)}
                                >
                                    <FaStar size={38} color="red" className={styles.eachStar} />
                                </span>
                                :
                                <span
                                    onClick={() => selectStarHandler(5)}
                                >
                                    <FaRegStar size={38} color="dfdfdf" className={styles.eachStar} />
                                </span>

                            }
                        </div>
                    </div>
                    {selectedStar > 0 && selectedStar < 3 ?
                        <div className={styles.furtherContainer}>
                            <div className={styles.whatWentWrongContainer}>
                                <div className={styles.wwwcMainTitle}>
                                    Tell us what went wrong!
                                </div>
                                <div className={styles.eachWwwcContainer}>
                                    <div className={starSelectedServiceResults > 0 ? styles.eachWwwcHeaderSelected : styles.eachWwwcHeader}>
                                        <div className={styles.eachWwwcTitle}>
                                            Service Results
                                        </div>
                                        <div className={styles.eachWwwcStarContainer}>
                                            {starSelectedServiceResults > 0 ?
                                                <span onClick={() => selectStarHandlerSR(1)}><FaStar size={18} color="red" className={styles.eachStar} /></span>
                                                :
                                                <span onClick={() => selectStarHandlerSR(1)}><FaRegStar size={18} color="dfdfdf" className={styles.eachStar} /></span>
                                            }
                                            {starSelectedServiceResults > 1 ?
                                                <span onClick={() => selectStarHandlerSR(2)}><FaStar size={18} color="red" className={styles.eachStar} /></span>
                                                :
                                                <span onClick={() => selectStarHandlerSR(2)}><FaRegStar size={18} color="dfdfdf" className={styles.eachStar} /></span>
                                            }
                                            {starSelectedServiceResults > 2 ?
                                                <span onClick={() => selectStarHandlerSR(3)}><FaStar size={18} color="red" className={styles.eachStar} /></span>
                                                :
                                                <span onClick={() => selectStarHandlerSR(3)}><FaRegStar size={18} color="dfdfdf" className={styles.eachStar} /></span>
                                            }
                                            {starSelectedServiceResults > 3 ?
                                                <span onClick={() => selectStarHandlerSR(4)}><FaStar size={18} color="red" className={styles.eachStar} /></span>
                                                :
                                                <span onClick={() => selectStarHandlerSR(4)}><FaRegStar size={18} color="dfdfdf" className={styles.eachStar} /></span>
                                            }
                                            {starSelectedServiceResults > 4 ?
                                                <span onClick={() => selectStarHandlerSR(5)}><FaStar size={18} color="red" className={styles.eachStar} /></span>
                                                :
                                                <span onClick={() => selectStarHandlerSR(5)}><FaRegStar size={18} color="dfdfdf" className={styles.eachStar} /></span>
                                            }
                                        </div>
                                    </div>
                                    <div>

                                    </div>
                                </div>
                                <div className={styles.eachWwwcContainer}>
                                    <div className={starSelectedStaffBehaviour > 0 ? styles.eachWwwcHeaderSelected : styles.eachWwwcHeader}>
                                        <div className={styles.eachWwwcTitle}>
                                            Staff Behaviour
                                        </div>
                                        <div>
                                            {starSelectedStaffBehaviour > 0 ?
                                                <span onClick={() => selectStarHandlerSB(1)}><FaStar size={18} color="red" className={styles.eachStar} /></span>
                                                :
                                                <span onClick={() => selectStarHandlerSB(1)}><FaRegStar size={18} color="dfdfdf" className={styles.eachStar} /></span>
                                            }
                                            {starSelectedStaffBehaviour > 1 ?
                                                <span onClick={() => selectStarHandlerSB(2)}><FaStar size={18} color="red" className={styles.eachStar} /></span>
                                                :
                                                <span onClick={() => selectStarHandlerSB(2)}><FaRegStar size={18} color="dfdfdf" className={styles.eachStar} /></span>
                                            }
                                            {starSelectedStaffBehaviour > 2 ?
                                                <span onClick={() => selectStarHandlerSB(3)}><FaStar size={18} color="red" className={styles.eachStar} /></span>
                                                :
                                                <span onClick={() => selectStarHandlerSB(3)}><FaRegStar size={18} color="dfdfdf" className={styles.eachStar} /></span>
                                            }
                                            {starSelectedStaffBehaviour > 3 ?
                                                <span onClick={() => selectStarHandlerSB(4)}><FaStar size={18} color="red" className={styles.eachStar} /></span>
                                                :
                                                <span onClick={() => selectStarHandlerSB(4)}><FaRegStar size={18} color="dfdfdf" className={styles.eachStar} /></span>
                                            }
                                            {starSelectedStaffBehaviour > 4 ?
                                                <span onClick={() => selectStarHandlerSB(5)}><FaStar size={18} color="red" className={styles.eachStar} /></span>
                                                :
                                                <span onClick={() => selectStarHandlerSB(5)}><FaRegStar size={18} color="dfdfdf" className={styles.eachStar} /></span>
                                            }
                                        </div>
                                    </div>
                                    <div>

                                    </div>
                                </div>
                                <div className={styles.eachWwwcContainer}>
                                    <div className={starSelectedSalonEnvironment > 0 ? styles.eachWwwcHeaderSelected : styles.eachWwwcHeader}>
                                        <div className={styles.eachWwwcTitle}>
                                            Salon Environment
                                        </div>
                                        <div>
                                            {starSelectedSalonEnvironment > 0 ?
                                                <span onClick={() => selectStarHandlerSE(1)}><FaStar size={18} color="red" className={styles.eachStar} /></span>
                                                :
                                                <span onClick={() => selectStarHandlerSE(1)}><FaRegStar size={18} color="dfdfdf" className={styles.eachStar} /></span>
                                            }
                                            {starSelectedSalonEnvironment > 1 ?
                                                <span onClick={() => selectStarHandlerSE(2)}><FaStar size={18} color="red" className={styles.eachStar} /></span>
                                                :
                                                <span onClick={() => selectStarHandlerSE(2)}><FaRegStar size={18} color="dfdfdf" className={styles.eachStar} /></span>
                                            }
                                            {starSelectedSalonEnvironment > 2 ?
                                                <span onClick={() => selectStarHandlerSE(3)}><FaStar size={18} color="red" className={styles.eachStar} /></span>
                                                :
                                                <span onClick={() => selectStarHandlerSE(3)}><FaRegStar size={18} color="dfdfdf" className={styles.eachStar} /></span>
                                            }
                                            {starSelectedSalonEnvironment > 3 ?
                                                <span onClick={() => selectStarHandlerSE(4)}><FaStar size={18} color="red" className={styles.eachStar} /></span>
                                                :
                                                <span onClick={() => selectStarHandlerSE(4)}><FaRegStar size={18} color="dfdfdf" className={styles.eachStar} /></span>
                                            }
                                            {starSelectedSalonEnvironment > 4 ?
                                                <span onClick={() => selectStarHandlerSE(5)}><FaStar size={18} color="red" className={styles.eachStar} /></span>
                                                :
                                                <span onClick={() => selectStarHandlerSE(5)}><FaRegStar size={18} color="dfdfdf" className={styles.eachStar} /></span>
                                            }
                                        </div>
                                    </div>
                                    <div>

                                    </div>
                                </div>
                            </div>

                            <div className={styles.careToShareMoreContainer}>
                                <div className={styles.careToShareMoreTitle}>
                                    Care to share a little more?
                                </div>
                                <textarea placeholder="Feel free to write your feedback here. The feedbacks are anonymous and are not directly shared with staff!"
                                    onChange={(e) => careToShareALilTxtHandler(e.target.value)}
                                    value={careToShareALilMoreTxt}
                                >

                                </textarea>
                            </div>
                        </div>
                        :
                        <div>
                        </div>
                    }

                    {selectedStar > 2 && selectedStar < 5 ?
                        <div className={styles.furtherContainer}>
                            <div className={styles.whatWentWrongContainer}>
                                <div className={styles.wwwcMainTitle}>
                                    Tell us what can be improved!
                                </div>
                                {wcbImprovedSelected.length>0 ?
                                    <div className={styles.wcbiOptions}>
                                        {whatCanBeImprovedOptions.map((item, index) => (
                                            <div className={wcbImprovedSelected.includes(item) === true ? styles.eachWcbiContainerSelected : styles.eachWcbiContainer} key={index}
                                                onClick={() => whatCanBeImprovedHandler(item)}
                                            >
                                                {item}
                                            </div>
                                        ))}
                                    </div>
                                    :
                                    <div className={styles.wcbiOptions}>
                                        {whatCanBeImprovedOptions.map((item, index) => (
                                            <div className={styles.eachWcbiContainer} key={index}
                                                onClick={() => whatCanBeImprovedHandler(item)}
                                            >
                                                {item}
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                            <div className={styles.careToShareMoreContainer}>
                                <div className={styles.careToShareMoreTitle}>
                                    Care to share a little more?
                                </div>
                                <textarea placeholder="Feel free to write your feedback here. The feedbacks are anonymous and are not directly shared with staff!"
                                    onChange={(e) => careToShareALilTxtHandler(e.target.value)}
                                    value={careToShareALilMoreTxt}
                                >

                                </textarea>
                            </div>
                        </div>
                        :
                        <div>
                        </div>
                    }
                    {selectedStar > 4 &&
                        <div className={styles.furtherContainer}>
                            <div className={styles.whatWentWrongContainer}>
                                <div className={styles.wwwcMainTitle}>
                                    Tip our rockstars!
                                </div>
                                <div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.careToShareMoreContainer}>
                                <div className={styles.careToShareMoreTitle}>
                                    Care to share a little more?
                                </div>
                                <textarea placeholder="Feel free to write your feedback here. The feedbacks are anonymous and are not directly shared with staff!"
                                    onChange={(e) => careToShareALilTxtHandler(e.target.value)}
                                    value={careToShareALilMoreTxt}
                                >

                                </textarea>
                            </div>
                        </div>
                    }
                </div>

            </section >
            <section className={styles.mainFooterWrapper}>
                <div className={styles.mainFooterContainer}>
                    {selectedStar > 0 ?
                        <button className={styles.submitBtnEnabled}
                            onClick={() => submitReviewHandler()}
                        >
                            Submit
                        </button>
                        :
                        <button className={styles.submitBtnDisabled}>
                            Submit
                        </button>
                    }
                </div>
            </section>
        </div >
    )

}

export default Home